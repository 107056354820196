@import url(https://rsms.me/inter/inter.css);
* {
  margin: 0;
  padding: 0;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.1;
}

body {
  background: #edfafa;
  font-family: 'Inter', sans-serif;
  font-size: 42px;
  color: #273444;
}

#root {
  height: 100%;
}

.img-fluid {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .img-fluid--pos {
  position: absolute;
  left: 0;
} */

.row {
  width: 100%;
}
.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}
.w-40 {
  width: 40%;
}

.row::before {
  content: '';
  clear: both;
  display: table;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.container {
  /* max-width: 50vw; */
  max-width: 1280px;
  margin: 0 auto;
}
.flags_top {
  width: 100%;
  position: fixed;
  top: 0;
}

.happy_birthday--big {
  padding: 0 30px 0 120px;
}

.happy_birthday--small {
  display: inline-block;
  width: 60%;
}

.happy_birthday--upcoming {
  display: inline-block;
  width: 40%;
}

.col-left {
  width: 60%;
  float: left;
}

.col-right {
  width: 40%;
  float: right;
}

.col-3 {
  width: 33.33%;
}
.col-list {
  max-width: calc((100% / 4) - 20px);
  width: 100%;
  padding: 1vw;
  box-sizing: border-box;
}
.col-list--upcoming {
  max-width: calc((100% / 3) - 20px);
}

.image-card {
  text-align: center;
  margin-bottom: 20px;
}

/* .image-card--flair {
  background: url('../assets/flair_big.svg') no-repeat top center;
} */

.img-hldr {
  /* max-width: 100%;
  width: 320px;
  height: 320px;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
  border: 8px solid #fff;
  box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  display: inline-block;
  position: relative; */
  /* padding: 1vw; */
}

.img-hldr img {
  width: 520px;
  border: 10px solid white;
  border-radius: 50%;
  box-sizing: border-box;
}

.image-card--upcoming .img-hldr {
  width: 140px;
  height: 140px;
  border: 5px solid #fff;
}

h2 {
  margin-top: 15px;
  font-size: 3rem;
  max-width: 500px;
  display: inline-block;
}

h3 {
  margin-top: 10px;
  font-size: 1.8rem;
  line-height: 12px;
}

.mt-1 {
  padding-top: 120px;
}

.mt-2 {
  padding-top: 40px;
}

.text-center {
  text-align: center;
}

.row-f {
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.container--center {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.date {
  font-size: 1vw;
  font-weight: 500;
  color: #737373;
}

.container--medium {
  margin: 0 auto;
  max-width: 80%;
}

.loader-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.loader-wrapper .loader {
  display: block;
  padding: 20px;
  text-align: center;
}

.loader-wrapper .loader svg path {
  fill: #ffffff;
}
.birthday-name {
  font-size: 1.2vw;
  line-height: 140%;
}
.birthday-name--single {
  font-size: 2.5vw;
}

.d-flex {
  display: flex;
  align-items: center;
  grid-gap: 145px;
  gap: 145px;
  justify-content: center;
}
.mt-20 {
  margin-top: 20px;
}
.w-100 {
  width: 100vw !important;
}
.w-400 {
  width: 420px !important;
}
.w-35 {
  width: 35%;
}
.max-width-unset {
  max-width: unset;
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    min-width: 0;
  }

  .d-flex {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    min-width: 0;
  }
  .img-hldr {
    width: 300px;
    height: 300px;
  }
  .d-flex {
    flex-direction: column;
  }

  .w-60 {
    width: auto;
  }
  .w-40 {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .happy_birthday--big {
    padding: 0 30px 0 30px;
  }

  .img-hldr--pos {
    position: unset;
  }
  .birthday-name {
    font-size: 1.375rem;
  }

  .col-list {
    max-width: 100%;
    width: 100%;
    padding: 1vw;
    box-sizing: border-box;
  }
}

